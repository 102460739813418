<template>
  <div>
    <div class="d-flex align-center">
      <v-img contain width="50" height="50" :src="pictureUrl"></v-img>
    </div>
    <div>
      <p class="text-center black--text">
        {{ name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "IrrigationSystemHealthItem",
  props: {
    pictureUrl: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    }
  }
};
</script>
