<template>
  <div id="syncscape_communityInfo">
    <CommunityHeader
      :communityName="communityInfo.name"
      :temperature="temperature"
      :humidity="humidity"
      :loading="weatherLoading || communityInfo == null"
      :weatherCode="weatherCode"
      :communityId="communityInfo.aspireId"
      :communityPhoto="communityPhoto"
      :isResidentView="false"
    />
    <v-card elevation="0" v-if="communityInfo.propertyManager">
      <v-card-text>
        <v-row>
          <v-col md="1" cols="2">
            <v-avatar size="50" color="primary">
              <span
                v-if="!communityInfo.propertyManager.photo"
                class="white--text headline"
                >{{ initials }}</span
              >
              <img
                v-else
                :src="communityInfo.propertyManager.photo.fileUrl"
                :alt="communityInfo.propertyManager.fullName"
              />
            </v-avatar>
          </v-col>
          <v-col md="3" cols="6">
            <h6>
              <span class="font-weight-bold">{{
                communityInfo.propertyManager.fullName
              }}</span
              >, {{ communityInfo.propertyManager.role }}
            </h6>
            <p>
              {{ communityInfo.propertyManager.phone }}&nbsp;
              <router-link
                class="blue--text"
                :to="
                  `/customers/${communityInfo.aspireId}/messages/new?recipient=${communityInfo.propertyManager.aspireId}`
                "
                >Message
                {{ communityInfo.propertyManager.fullName }}</router-link
              >
            </p>
            <h6 class="font-weight-bold">
              Managed by:
            </h6>
            <div>{{ communityInfo.managementCompany.name }}</div>
            <div>{{ communityInfo.managementCompany.address.line1 }}</div>
            <div
              v-if="
                communityInfo.managementCompany.address.city ||
                  communityInfo.managementCompany.address.stateCode ||
                  communityInfo.managementCompany.address.postalCode
              "
            >
              {{ communityInfo.managementCompany.address.city }},
              {{ communityInfo.managementCompany.address.stateCode }}
              {{ communityInfo.managementCompany.address.postalCode }}
            </div>
            <div v-if="communityInfo.managementCompany.address.phone">
              {{ communityInfo.managementCompany.address.phone }}
            </div>
            <div cols="12" md="2" v-if="relatedCommunityItems.length > 1">
              <v-select
                v-model="relatedCommunity"
                :items="relatedCommunityItems"
                :loading="relatedLoading"
                item-text="communityName"
                item-value="aspireId"
                label="Select Related Community..."
              >
                <!-- <template v-slot:selection="{ item }">
                      <router-link :to="`/customers/${item.id}`">
                        {{ item.name }}
                      </router-link>
                    </template> -->
              </v-select>
            </div>
          </v-col>
          <v-col justify="space-around">
            <CommunitySentiment :communityInfo="communityInfo" />
          </v-col>
          <v-col justify="space-around" cols="12" md="4" lg="4" class="mx-auto">
            <h6 class="font-weight-bold text-center">
              <span>Service Request</span>&nbsp;<router-link
                :to="`/customers/${communityInfo.aspireId}/requests`"
                v-slot="{ href, navigate, isExactActive }"
              >
                <a
                  :href="href"
                  class="link"
                  :class="[isExactActive && 'link-active']"
                  @click="navigate"
                >
                  View All
                </a></router-link
              >
            </h6>
            <v-row justify="center">
              <v-col class="text-center" md="4" lg="4" sm="4">
                <h1 class="text-center" style="color: #df6526;">
                  <v-progress-circular
                    v-if="requestsLoading"
                    :active="requestsLoading"
                    :indeterminate="requestsLoading"
                    color="primary"
                  ></v-progress-circular>
                  <span v-if="!requestsLoading"
                    >{{
                      communityInfo.serviceRequestsNumbers.open
                      /* communityInfo.serviceRequests.filter(el => el.isOpen || el.isInProcess).length*/
                    }}
                  </span>
                </h1>
                <span class="text-center">Open</span>
              </v-col>
              <v-col class="text-center" md="4" lg="4" sm="4">
                <h1 class="text-center" style="color: #df6526;">
                  <v-progress-circular
                    v-if="requestsLoading"
                    :active="requestsLoading"
                    :indeterminate="requestsLoading"
                    color="primary"
                  ></v-progress-circular>
                  <span v-if="!requestsLoading"
                    >{{
                      communityInfo.serviceRequestsNumbers.inProcess
                      /* communityInfo.serviceRequests.filter(el => el.isInProcess).length */
                    }}
                  </span>
                </h1>
                <span class="text-center">In Process</span>
              </v-col>
              <v-col class="text-center" md="4" lg="4" sm="4">
                <h1 class="text-center" style="color: #31c0e3;">
                  <v-progress-circular
                    v-if="requestsLoading"
                    :active="requestsLoading"
                    :indeterminate="requestsLoading"
                    color="primary"
                  ></v-progress-circular>
                  <span v-if="!requestsLoading">
                    {{
                      communityInfo.serviceRequestsNumbers.closed
                      /* communityInfo.serviceRequests.filter(el => el.isCompleted).length*/
                    }}
                  </span>
                </h1>
                <span class="text-center">Closed</span>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col class="text-center">
                <router-link
                  :to="`/customers/${communityInfo.aspireId}/requests/new`"
                >
                  <v-btn small color="blue-bg" rounded class="white--text">
                    New Service Request
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <hr style="background-color: #df6526; height: 1px;" />
        <!-- <v-divider ></v-divider> -->
        <BranchOfficeInfo
          :branchOfficeStaff="communityInfo.branchOfficeStaff"
        />
        <hr style="background-color: #df6526; height: 1px;" />
        <v-row>
          <v-col cols="6" md="2">
            <v-dialog
              v-model="serviceStatusDialog"
              persistent
              max-width="600px"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">Service Status Alert</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-item-group v-model="serviceAlert.reason">
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="3">
                            <v-item v-slot="{ active, toggle }">
                              <v-card
                                :color="
                                  active ? 'blue lighten-4' : 'transparent'
                                "
                                dark
                                @click="toggle"
                              >
                                <ServiceStatusItem
                                  statusName="On-Schedule"
                                  statusPictureUrl="/media/assets/service-status/icons-on-schedule.png"
                                >
                                </ServiceStatusItem>
                              </v-card>
                            </v-item>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-item v-slot="{ active, toggle }">
                              <v-card
                                :color="
                                  active ? 'blue lighten-4' : 'transparent'
                                "
                                dark
                                @click="toggle"
                              >
                                <ServiceStatusItem
                                  statusName="Rain Out"
                                  statusPictureUrl="/media/assets/service-status/icons-rain-out.png"
                                >
                                </ServiceStatusItem>
                              </v-card>
                            </v-item>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-item v-slot="{ active, toggle }">
                              <v-card
                                :color="
                                  active ? 'blue lighten-4' : 'transparent'
                                "
                                dark
                                @click="toggle"
                              >
                                <ServiceStatusItem
                                  statusName="Holiday"
                                  statusPictureUrl="/media/assets/service-status/icons-holiday.png"
                                >
                                </ServiceStatusItem>
                              </v-card>
                            </v-item>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-item v-slot="{ active, toggle }">
                              <v-card
                                :color="
                                  active ? 'blue lighten-4' : 'transparent'
                                "
                                dark
                                @click="toggle"
                              >
                                <ServiceStatusItem
                                  statusName="Conflict"
                                  statusPictureUrl="/media/assets/service-status/icons-conflict.png"
                                >
                                </ServiceStatusItem>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-item-group>
                    <v-row>
                      <v-col cols="12">
                        <v-radio-group v-model="serviceAlert.target" row>
                          <v-radio
                            label="This customer only"
                            value="onlyToCustomer"
                            class="v-radio-no-margin"
                          ></v-radio>
                          <v-radio
                            label="All customers at this branch"
                            value="allCustomersInBranch"
                            class="v-radio-no-margin"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="serviceAlert.rescheduleDateItem"
                          :items="serviceAlertDateItems"
                          item-text="name"
                          item-value="value"
                          label="Select Reschedule Date..."
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div
                          v-if="
                            serviceAlert.rescheduleDateItem == 'Custom Date'
                          "
                        >
                          <v-menu
                            ref="alertMenu"
                            v-model="alertMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formattedAlertDate"
                                label="Reschedule Date"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                @blur="
                                  alertDate = parseDate(formattedAlertDate)
                                "
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="alertDate"
                              no-title
                              @input="alertMenu = false"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="serviceStatusDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    class="orange-bg white--text"
                    text
                    @click="updateServiceStatus()"
                  >
                    Reschedule
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <h6 class="text-center">
              Service Status
            </h6>

            <div class="text-center" v-if="serviceStatusLoading">
              <v-progress-circular
                :active="serviceStatusLoading"
                :indeterminate="serviceStatusLoading"
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-if="!serviceStatusLoading">
              <div class="mx-auto" style="width: 50px;">
                <v-img
                  v-model="serviceStatus.status"
                  :src="getServiceStatusIconPath(serviceStatus)"
                ></v-img>
              </div>

              <p class="text-center mt-3 mb-0">
                {{ serviceStatus.status }}
                <a
                  href="javascript:;"
                  class="blue--text"
                  @click.stop="serviceStatusDialog = true"
                >
                  Update
                </a>
              </p>
              <!-- <p
                class="text-center mt-0"
                v-if="serviceStatus.noServiceThisWeek"
              >
                No service this week
              </p>
              <p
                class="text-center mt-0"
                v-else-if="serviceStatus.rescheduledDate"
              >
                Rescheduled
                {{ formatDateUs(serviceStatus.rescheduledDate) }}
              </p> -->

              <p class="text-center mt-0" v-if="serviceStatus.rescheduledText">
                {{ serviceStatus.rescheduledText }}
              </p>
            </div>
          </v-col>
          <v-col cols="6" md="2">
            <h6 class="text-center">
              Orange Report
            </h6>
            <div class="mx-auto" style="width: 40px;">
              <router-link
                class="blue--text"
                :to="`/customers/${communityInfo.aspireId}/orange-reports/new`"
              >
                <v-img src="/media/assets/icons/icons-report.png"></v-img>
              </router-link>
            </div>
            <p class="text-center green--text mt-3 mb-0">
              <router-link
                class="blue--text"
                :to="`/customers/${communityInfo.aspireId}/orange-reports/new`"
                >Create New</router-link
              >
            </p>
            <p class="text-center green--text mt-0" v-if="orangeReportsLoading">
              <v-progress-circular
                :indeterminate="orangeReportsLoading"
                color="primary"
                size="20"
              ></v-progress-circular>
            </p>

            <p
              class="text-center green--text mt-0"
              v-if="!orangeReportsLoading && pendingOrangeReportsNumber > 0"
            >
              <router-link
                class="green--text"
                :to="
                  `/customers/${communityInfo.aspireId}/orange-reports/pending`
                "
              >
                Pending Approval
              </router-link>
            </p>
          </v-col>
          <v-col cols="6" md="2">
            <h6 class="text-center">
              Community Map
            </h6>
            <div class="mx-auto" style="width: 40px;">
              <router-link
                class="blue--text"
                :to="`/customers/${communityInfo.aspireId}/update-map`"
              >
                <v-img src="/media/assets/icons/icons-map.png"></v-img>
              </router-link>
            </div>
            <p class="text-center blue--text mt-3">
              <router-link
                class="blue--text"
                :to="`/customers/${communityInfo.aspireId}/update-map`"
                >View / Update</router-link
              >
            </p>
          </v-col>
          <v-col>
            <ServiceCenterBox :id="communityInfo.aspireId" />
          </v-col>
        </v-row>
        <hr style="background-color: #94B43F; height: 5px;" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";

import CommunityHeader from "@/view/components/CommunityHeader.vue";
import CommunitySentiment from "@/view/components/CommunitySentiment.vue";
import BranchOfficeInfo from "@/view/components/BranchOfficeInfo.vue";
import ServiceCenterBox from "@/view/components/ServiceCenterBox.vue";
import ServiceStatusItem from "@/view/components/ServiceStatusItem.vue";
import moment from "moment";
import {
  GET_QUERY,
  POST_DATA,
  API_WEATHER,
  API_CUSTOMERS,
  API_SERVICE_REQUESTS
} from "@/core/store/crud.module";

export default {
  name: "CommunityInfo",
  components: {
    CommunityHeader,
    CommunitySentiment,
    ServiceCenterBox,
    BranchOfficeInfo,
    ServiceStatusItem
  },
  data: () => ({
    serviceStatusDialog: false,
    serviceAlert: {
      rescheduleDateItem: "",
      reason: "",
      target: "onlyToCustomer",
      date: null,
      date2: null,
      noServiceThisWeek: false
    },
    serviceStatus: {},
    serviceStatusLoading: false,
    serviceAlertDate: null,
    serviceAlertDateItems: [
      {
        name: "Next business day",
        value: "Next business day"
      },
      {
        name: "2 business days later",
        value: "2 business days later"
      },
      {
        name: "No service this week",
        value: "No service this week"
      },
      {
        name: "Custom Date",
        value: "Custom Date"
      }
    ],
    relatedCommunity: null,
    relatedCommunityItems: [],
    alertMenu: false,
    formattedAlertDate: null,
    alertDate: null,
    isLoading: false,
    loading: false,
    weatherLoading: false,
    relatedLoading: false,
    temperature: 0,
    humidity: 0,
    weatherCode: 800,
    requestsLoading: false,
    recentOrangeReports: false,
    pendingOrangeReports: [],
    pendingOrangeReportsNumber: 0,
    orangeReportsLoading: false
  }),
  props: {
    communityInfo: Object
    // relatedCommunityItems: Array
  },
  async mounted() {
    await this.getServiceStatus();
    await this.getRelatedComunities();
    // await this.getServiceRequests();
    await this.getServiceRequestsNumbers();
    await this.getWeather();
    await this.getRecentOrangeReports();
  },
  async created() {},
  watch: {
    relatedCommunity: async function() {
      // let path = "/customers/" + this.relatedCommunity;
      // this.$router.push(path);
      this.$emit("onRelatedCommunityChanged", this.relatedCommunity);
      // this.$router.push({
      //   name: "CustomerDetails",
      //   params: { customerId: this.relatedCommunity }
      // });
    },
    // $route: async function() {},
    alertDate: function() {
      this.formattedAlertDate = this.formatDate(this.alertDate);
    }
  },
  methods: {
    async getRecentOrangeReports() {
      if (!this.communityInfo.aspireId) return;
      this.orangeReportsLoading = true;
      this.pendingOrangeReportsNumber = 0;

      let reportsUrl = `${API_CUSTOMERS}/${this.communityInfo.aspireId}/orangeReports/numbers`;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: reportsUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.pendingOrangeReportsNumber = response.data.pending;
            // this.pendingOrangeReports = response.data.$values.filter(
            //   el => !el.isApproved
            // );
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          this.orangeReportsLoading = false;
        });
    },
    async getServiceStatus() {
      if (this.serviceStatusLoading) return;
      if (!this.communityInfo.aspireId) return;

      this.serviceStatusLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_CUSTOMERS}/${this.communityInfo.aspireId}/serviceStatus`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.serviceStatus = response.data;
            if (!this.serviceStatus) {
              this.serviceStatus = {
                status: "On-Schedule",
                rescheduledDate: null,
                noServiceThisWeek: false
              };
            }
          }
          // else {
          // }
          this.serviceStatusLoading = false;
        });
    },
    async getWeather() {
      if (this.weatherLoading) return;

      if (
        !this.communityInfo ||
        !this.communityInfo.address ||
        !this.communityInfo.address.postalCode
      )
        return;

      this.weatherLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_WEATHER}/${this.communityInfo.address.postalCode}`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.temperature = response.data.currentTemperature;
            this.humidity = response.data.currentHumidity;
            this.weatherCode = response.data.currentCondition.id;
          }
          // else {
          // }
          this.weatherLoading = false;
        });
    },
    getServiceStatusIconPath(serviceStatus) {
      switch (serviceStatus.status) {
        case "On-Schedule":
          return "/media/assets/service-status/icons-on-schedule.png";
        case "Rain-Out":
          return "/media/assets/service-status/icons-rain-out.png";
        case "Holiday":
          return "/media/assets/service-status/icons-holiday.png";
        case "Conflict":
          return "/media/assets/service-status/icons-conflict.png";
      }
    },
    updateServiceStatus() {
      if (this.serviceStatusLoading) return;

      let statusStr = "";
      switch (this.serviceAlert.reason) {
        case 0:
          statusStr = "On-Schedule";
          break;
        case 1:
          statusStr = "Rain-Out";
          break;
        case 2:
          statusStr = "Holiday";
          break;
        case 3:
          statusStr = "Conflict";
          break;
      }

      this.serviceAlert.noServiceThisWeek = false;
      let rescheduledDate = moment.utc();

      switch (this.serviceAlert.rescheduleDateItem) {
        case "Next business day":
          rescheduledDate = this.getBusinessDate(rescheduledDate, 1);
          break;
        case "2 business days later":
          rescheduledDate = this.getBusinessDate(rescheduledDate, 2);
          break;
        case "No service this week":
          rescheduledDate.add(1, "weeks");
          this.serviceAlert.noServiceThisWeek = true;
          break;
        case "Custom Date":
          rescheduledDate = moment.utc(this.alertDate);
          break;
        default:
          break;
      }

      const newStatus = {
        status: statusStr,
        rescheduledDate: rescheduledDate,
        // noServiceThisWeek: this.serviceAlert.noServiceThisWeek,
        updateAllCustomersInBranch:
          this.serviceAlert.target == "allCustomersInBranch",
        rescheduledText: this.serviceAlert.noServiceThisWeek
          ? "No service this week"
          : `Rescheduled ${this.formatDateUs(rescheduledDate)}`
      };
      this.communityInfo.serviceStatus = newStatus;

      this.serviceStatusLoading = true;

      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_CUSTOMERS}/${this.communityInfo.aspireId}/serviceStatus`,
          data: newStatus
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.serviceStatus = newStatus;
            this.$snackbar.showMessage({
              content: "Service Status has been updated"
            });
          }
          this.serviceStatusLoading = false;
        });

      this.serviceStatusDialog = false;
    },

    getBusinessDate(date, days) {
      let newDate = moment.utc(date).add(days, "days");
      if (newDate.isoWeekday() == 6) newDate.add(2, "days");
      if (newDate.isoWeekday() == 7) newDate.add(1, "days");
      return newDate;
    },
    async getRelatedComunities() {
      if (!this.communityInfo.aspireId) return;
      this.relatedLoading = true;
      this.communityInfo.relatedCommunitiesLoading = true;
      this.$emit("onRelatedCommunityLoading");

      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_CUSTOMERS}/${self.communityInfo.aspireId}/relatedCustomers`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.relatedCommunityItems = response.data;
            self.communityInfo.relatedCommunities = self.relatedCommunityItems;
            self.communityInfo.relatedCommunitiesLoading = false;
          }
          self.relatedLoading = false;
          self.$emit("onRelatedCommunityLoaded", self.relatedCommunityItems);
        });
    },
    async getServiceRequestsNumbers() {
      if (this.requestsLoading) return;
      if (!this.communityInfo.aspireId) return;

      this.requestsLoading = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_SERVICE_REQUESTS}/Community/${this.communityInfo.aspireId}/numbers`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo.serviceRequestsNumbers = response.data;
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Service Requests Numbers. Something went wrong!",
              color: "red"
            });
          }

          this.requestsLoading = false;
        });
    },
    // async getServiceRequests() {
    //   if (this.requestsLoading) return;

    //   this.requestsLoading = true;
    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: `${API_SERVICE_REQUESTS}/Community`,
    //       id: this.communityInfo.aspireId
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         this.communityInfo.serviceRequests = response.data.$values;
    //       } else {
    //         this.$snackbar.showMessage({
    //           content:
    //             response.data ||
    //             "Cannot receive Service Requests. Something went wrong!",
    //           color: "red"
    //         });
    //       }

    //       this.requestsLoading = false;
    //     });
    // },

    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment.utc(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    initials() {
      return stringFormatter.initials(
        this.communityInfo?.propertyManager?.firstName,
        this.communityInfo?.propertyManager?.lastName
      );
    },
    communityPhoto() {
      return this.communityInfo?.photo?.fileUrl;
    }
  }
};
</script>
