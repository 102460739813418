<template>
  <div>
    <div class="d-flex align-center">
      <v-img contain width="100" height="100" :src="statusPictureUrl"></v-img>
    </div>
    <div>
      <p class="text-center black--text">
        {{ statusName }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceStatusItem",
  props: {
    statusPictureUrl: {
      type: String,
      default: ""
    },
    statusName: {
      type: String,
      default: ""
    }
  }
};
</script>
