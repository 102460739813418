<template>
  <div class="text-center">
    <h6 class="font-weight-bold">
      Community Landscape Quality Sentiment
    </h6>
    <div style="width: 290px;" class="mt-3 mx-auto">
      <v-rating
        v-model="communityInfo.sentiment.value"
        hover
        half-increments
        length="5"
        readonly
      >
        <template v-slot:item="props">
          <img
            height="58"
            width="49"
            class="mx-1"
            :src="
              props.isFilled
                ? 'media/assets/active-leaf.png'
                : props.isHalfFilled
                ? 'media/assets/half-leaf.png'
                : 'media/assets/inactive-leaf.png'
            "
          />
        </template>
      </v-rating>
      <!-- <v-slider
        v-model="communityInfo.sentiment"
        step="0.5"
        min="0"
        max="5"
        thumb-label
        ticks
      ></v-slider> -->

      <p class="text-h4 font-weight-black mt-2 text-center">
        {{ communityInfo.sentiment.value }} / 5
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommunitySentiment",
  props: {
    communityInfo: Object
  }
};
</script>

<style></style>
