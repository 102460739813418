<template>
  <v-col cols="12" class="pa-0">
    <div class="image-uploader">
      <div
        v-show="$refs.upload && $refs.upload.dropActive && fullScreenDropArea"
        class="text-center drop-active-full-screen"
      >
        <h3>Drop files to upload</h3>
      </div>
      <div
        v-show="$refs.upload && $refs.upload.dropActive && !fullScreenDropArea"
        class="text-center drop-active"
      >
        <h3>Drop files to upload</h3>
      </div>
      <div class="image-upload">
        <Spinner
          class="pt-12"
          v-show="loading"
          size="big"
          line-fg-color="#5b9bd1"
        />
        <div
          v-show="!loading"
          class="text-center p-2 py-4"
          style="display:inherit"
        >
          <div v-if="fileUrl && fileUrl.length && isFile">
            <a :href="fileUrl">{{ fileName }}</a>
          </div>
          <v-img
            v-if="fileUrl && fileUrl.length && isImage"
            :id="'img_' + id"
            aspect-ratio="1.7"
            width="100%"
            height="100%"
            max-width="305"
            max-height="150"
            position="center center"
            :contain="true"
            :src="fileUrl"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <!-- <img :src="fileUrl" class="image-thumbnail" /> -->

          <label
            v-if="fileUrl && fileUrl.length && !isImage && isVideo"
            for="image"
          >
            <video
              :id="'vid_' + id"
              :src="fileUrl"
              controls
              class="image-thumbnail"
            ></video>
          </label>
          <label class="pt-4" v-if="!fileUrl || fileUrl.length < 1">
            <inline-svg src="/media/svg/icons/files/file.svg" />
          </label>
        </div>
        <div
          v-show="!loading && (!fileUrl || !fileUrl.length)"
          :class="!loading && fileUrl && fileUrl.length ? '' : 'text-center'"
        >
          <file-upload
            ref="upload"
            :id="id"
            :input-id="'upl_' + id"
            :name="uploadType"
            :headers="headers"
            v-model="currentFiles"
            :post-action="apiUrl"
            :extensions="extensions"
            :multiple="multiple"
            :accept="accept"
            :drop="true"
            @input="inputUpdate"
            @input-filter="inputFilter"
            @input-file="inputFile"
          >
            <div v-if="!fileUrl || !fileUrl.length">
              <v-btn text small color="primary">
                Browse file
              </v-btn>
              or drop file
              <p>
                Max file size is <span v-if="uploadType == 'photo'">10 MB</span
                ><span v-else>100 MB</span>
              </p>
            </div>
          </file-upload>
        </div>
      </div>
    </div>
    <div class="image-uploader-links float-left" v-if="loading">
      <a
        href="#"
        @click.prevent="cancelUploading"
        class="ml-2 image-uploader-links"
      >
        Cancel</a
      >
    </div>
    <div
      class="image-uploader-links"
      v-if="!loading && fileUrl && fileUrl.length"
    >
      <div class="image-uploader-change " v-if="!loading && fileUrl.length">
        <!-- <div class="image-uploader-filename">{{ fileName }}</div> -->
        <!-- <a
          href="#"
          @click.prevent="changeImg($event)"
          class="mx-3 image-uploader-links"
          >Change</a
        > -->
        <a
          href="#"
          @click.prevent="deleteImg($event)"
          class="ml-2 image-uploader-links"
        >
          Delete</a
        >
        <a
          v-if="isImage"
          @click.stop="viewImageDialog = true"
          class="ml-2 image-uploader-links"
        >
          View
        </a>

        <v-dialog
          v-model="viewImageDialog"
          fullscreen
          transition="dialog-bottom-transition"
        >
          <v-card tile>
            <v-toolbar flat dark class="orange-bg">
              <v-spacer></v-spacer>
              <v-btn icon dark @click="viewImageDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-responsive :aspect-ratio="16 / 9">
              <v-card-text>
                <v-img
                  v-if="fileUrl && fileUrl.length && isImage"
                  :id="'preview_img_' + id"
                  aspect-ratio="1.7"
                  min-height="600"
                  contain
                  position="center center"
                  :src="fileUrl"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card-text>
            </v-responsive>

            <div style="flex: 1 1 auto;"></div>
          </v-card>
        </v-dialog>
        <!-- <div>
          <v-checkbox
            v-if="showOrangeReportFields"
            label="Display this image with the orange report listing"
            v-model="showInReportModel"
          />
          <v-textarea
            v-if="showOrangeReportFields"
            v-model="descriptionModel"
            label="Photo description"
          ></v-textarea>
        </div> -->
      </div>
    </div>
  </v-col>
</template>

<script>
import FileUpload from "vue-upload-component";
import Spinner from "vue-simple-spinner";
// import mediaItemsHelper from "@/core/helpers/mediaItemsHelper";
import { API_PHOTO_UPLOAD, API_FILE_UPLOAD } from "@/core/store/crud.module";
import { mapGetters } from "vuex";
import Mgr from "@/core/services/security.service";

export default {
  components: {
    FileUpload,
    Spinner
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    extensions: {
      type: String,
      required: true,
      default: "undefined"
    },
    id: {
      type: String,
      required: true
    },
    accept: {
      type: String,
      required: true,
      default: "*/*"
    },
    sizeImage: {
      type: Number,
      required: true
    },
    sizeVideo: {
      type: Number,
      required: true
    },
    fileId: {
      type: Number,
      default: 0
    },
    fileUrl: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isUploading: {
      type: Boolean,
      default: true
    },
    fullScreenDropArea: {
      type: Boolean,
      default: true
    },
    uploadType: {
      type: String,
      default: "photo"
    },
    showOrangeReportFields: {
      type: Boolean,
      default: false
    },
    showInReport: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      imageExt: "bmp,gif,jpg,jpeg,png,webp,tiff",
      videoExt: "mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt",
      // apiUrl: "https://socialglow-staging.s3-accelerate.amazonaws.com",
      edit: false,
      files: [],
      loading: false,
      isMounted: false,
      multipleUploadStore: [],
      baseUrl: `${process.env.VUE_APP_API_URL}`,
      accessToken: "",
      // headers: { authorization: "Bearer code" }
      showInReportModel: false,
      descriptionModel: "",
      checkedPhotoModel: "",
      viewImageDialog: false
    };
  },
  created() {
    let mgr = new Mgr();
    // let token = "";
    mgr.getAcessToken().then(accessToken => {
      this.accessToken = accessToken;
    });

    this.showInReportModel = this.showInReport;
    this.descriptionModel = this.description;
  },
  watch: {
    // showInReport(newVal) {
    //   this.showInReportModel = newVal;
    //   console.log("set show in report", this.fileUrl, newVal);
    // },
    showInReportModel(newVal) {
      this.$emit("update:showInReport", newVal);
      // this.$nextTick(() =>
      //   this.$emit("onShowInReportChanged", {
      //     fileUrl: this.fileUrl,
      //     state: newVal
      //   })
      // );
    },
    descriptionModel(newVal) {
      this.$emit("update:description", newVal);
    },
    allUploaded(newVal, oldVal) {
      if (!this.multipleUploadStore || !this.multipleUploadStore[0]) return;

      if (typeof newVal == "boolean") {
        this.$emit("update:isUploading", !newVal);
      }
      if (
        this.multiple &&
        typeof newVal === "boolean" &&
        typeof oldVal === "boolean" &&
        oldVal === false
      ) {
        this.$emit("onMultipleUploaded", this.multipleUploadStore);
        this.fileUrl = this.multipleUploadStore[0].fileUrl;
        this.fileId = this.multipleUploadStore[0].id;
        this.$emit("update:fileUrl", this.fileUrl);
        this.$emit("update:fileId", this.fileId);
        this.multipleUploadStore = [];
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters([
      "authToken",
      "awsSecrets",
      "currentUserInfo",
      "isAuthenticated"
    ]),
    allUploaded() {
      if (this.isMounted) return this.$refs.upload.uploaded;
      return null;
    },
    headers() {
      return { authorization: `Bearer ${this.accessToken}` };
    },

    // requestData() {
    //   if (!this.awsSecrets) return {};
    //   return {
    //     key: "",
    //     AWSAccessKeyId: this.awsSecrets.accessKeyId
    //       ? this.awsSecrets.accessKeyId
    //       : "",
    //     acl: "public-read",
    //     policy: this.awsSecrets.policy ? this.awsSecrets.policy : "",
    //     signature: this.awsSecrets.signature ? this.awsSecrets.signature : "",
    //     "Content-Type": "",
    //     filename: "",
    //     file: ""
    //   };
    // },
    currentFiles: {
      get() {
        return this.files;
      },
      set(value) {
        this.files = value;
      }
    },
    fileName() {
      if (this.files && this.files.length > 0) {
        let name = this.files[0].name;
        if (name.length > 23) name = name.substring(0, 23) + "...";
        return name;
      }
      return null;
    },
    isFile() {
      return !this.isImage && !this.isVideo;
    },
    isImage() {
      const fileParts = this.fileUrl.split(".");
      if (fileParts.length < -1) return true;
      const fileExt = fileParts[fileParts.length - 1].toLowerCase();
      if (this.imageExt.split(",").indexOf(fileExt) > -1) return true;
      return false;
    },
    isVideo() {
      const fileParts = this.fileUrl.split(".");
      if (fileParts.length < -1) return true;
      const fileExt = fileParts[fileParts.length - 1].toLowerCase();
      if (this.videoExt.split(",").indexOf(fileExt) > -1) return true;
      return false;
    },
    apiUrl() {
      if (this.uploadType === "file")
        return `${this.baseUrl}${API_FILE_UPLOAD}`;
      else return `${this.baseUrl}${API_PHOTO_UPLOAD}`;
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    cancelUploading(event) {
      if (event) {
        event.preventDefault();
      }
      this.$refs.upload.active = false;
      const self = this;
      this.$nextTick(function() {
        // self.files.foreach(file => {
        //   self.$refs.upload.update(file, { active: false });
        // });
        self.loading = false;
      });
    },
    inputUpdate() {
      if (!this.$refs.upload || !this.$refs.upload.active) {
        const self = this;
        this.$nextTick(function() {
          self.loading = true;
        });

        this.$refs.upload.active = true;
      }
    },
    changeImg(event) {
      if (event) {
        event.preventDefault();
      }
      var input = document.getElementById(this.id);
      if (input) input.click();
    },
    deleteImg(event) {
      if (event) {
        event.preventDefault();
      }
      const deletedImgUrl = this.fileUrl;
      // const deletedFileId = this.fileId;
      this.fileUrl = "";
      this.fileId = 0;
      this.$emit("update:fileUrl", this.fileUrl);
      this.$emit("update:fileId", this.fileId);
      this.$emit("onChangeFile", this.fileUrl);
      this.$emit("onDeleteFile", {
        index: this.index,
        fileUrl: deletedImgUrl
      });
    },
    inputFile(newFile, oldFile) {
      // var user = this.currentUserInfo;
      // Add
      // if (newFile && !oldFile) {
      //   if (newFile.data) {
      //     newFile.data.file = newFile.file;
      //     newFile.data["Content-Type"] = newFile.file.type;
      //     newFile.data.filename = newFile.file.name;
      //     newFile.data.key =
      //     // mediaItemsHelper.createRelativeMediaItemUrl(
      //     //   user.userId,
      //     //   newFile.file.name,
      //     //   newFile.file.type
      //     // );
      //     this.$emit("update:fileUrl", this.fileUrl);
      //     this.$emit("onAddFile", this.fileUrl);
      //     this.$emit("onChange", this.fileUrl);
      //     this.loading = false;
      //   }
      // }

      // Update
      if (newFile && oldFile) {
        /*if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);

          // min size
          if (newFile.size >= 0 && newFile.size < 100 * 1024) {
            newFile = this.$refs.upload.update(newFile, { error: "size" });
          }
        }*/
        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          // newFile.response.fileUrl = mediaItemsHelper.createMediaItemUrl(
          //   this.apiUrl,
          //   user.userId,
          //   newFile.file.name,
          //   newFile.file.type
          // );

          if (!this.multiple) {
            this.fileUrl = newFile.response.fileUrl;
            this.fileId = newFile.response.id;
            this.$emit("update:fileUrl", this.fileUrl);
            this.$emit("update:fileId", this.fileId);
            this.$emit("onChangeFile", this.fileUrl);
            this.$emit("onChange", { id: this.fileId, fileUrl: this.fileUrl });
            this.loading = false;
          } else {
            this.multipleUploadStore.push({
              id: newFile.response.id,
              fileUrl: newFile.response.fileUrl
            });
          }
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile.file.type.indexOf("image") > -1) {
        if (newFile.file.size > this.sizeImage) {
          alert(
            `Image file is too big. Maximum image size is ${this.formatBytes(
              this.sizeImage
            )}.`
          );
          return prevent();
        }
      } else if (newFile.file.type.indexOf("video") > -1) {
        if (newFile.file.size > this.sizeVideo) {
          alert(
            `Video file is too big. Maximum video size is ${this.formatBytes(
              this.sizeVideo
            )}.`
          );
          return prevent();
        }
      } else {
        if (newFile.file.size > this.sizeVideo) {
          alert(
            `File is too big. Maximum file size is ${this.formatBytes(
              this.sizeVideo
            )}.`
          );
          return prevent();
        }
      }
      return null;
    }
  }
};
</script>

<style scoped>
.image-uploader {
  max-width: 321px;
  height: 187px;
  border: 1px solid #c8d1ea;
  box-sizing: border-box;
  border-radius: 5px;
}
.image-upload {
  max-height: 200px;
}
.image-uploader-filename {
  font-size: 13px;
  display: inline-block;
  min-width: 190px;
}
.image-uploader-links {
  font-size: 13px;
}
.image-uploader .image-upload .image-thumbnail {
  vertical-align: top;
  box-sizing: border-box;
  width: auto;
  max-height: 180px;
  max-width: 85%;
}
.image-uploader .text-center .btn {
  margin: 0 0.5rem;
}
.image-uploader .drop-active-full-screen {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.image-uploader .drop-active-full-screen h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.image-uploader .drop-active {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.image-uploader .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
  color: #fff;
  padding: 0;
}
</style>
