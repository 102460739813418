<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined :loading="requestProcessing">
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
          <p class="mt-4 text-center">Processing...</p>
        </template>
        <v-card-title v-if="!requestProcessing" class="orange-bg">
          <v-row>
            <v-col cols="12" md="3">
              New Orange Report
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedServiceDate"
                    label="Service Date*"
                    prepend-icon="mdi-calendar"
                    :error-messages="formattedServiceDateErrors"
                    v-bind="attrs"
                    @blur="serviceDate = parseDate(formattedServiceDate)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="serviceDate"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="5" class="text-right">
              <v-btn color="primary" class="mx-1" @click="saveOrangeReport"
                >Save Report for approval</v-btn
              >
              <router-link
                class="blue--text mx-1"
                :to="`/customers/${customerId}/orange-reports`"
                ><v-btn>Cancel</v-btn></router-link
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!requestProcessing">
          <!-- Start New Orange Reports section -->
          <v-container>
            <v-row>
              <v-col cols="12" md="9">
                <v-form>
                  <v-container>
                    <h6 class="font-weight-bold">Services Rendered*</h6>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="4"
                        md="3"
                        v-for="(service, i) in dbOrangeReportServices"
                        :key="'service_col_' + i"
                      >
                        <v-checkbox
                          v-model="renderedServices"
                          :label="service.name"
                          :value="service.name"
                          color="blue"
                          class="v-checkbox-no-margin"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row>
                      <v-col>
                        <h6 class="font-weight-bold">Visit Notes</h6>
                        <v-textarea
                          v-model="orangeReport.visitNotes"
                          solo
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row>
                      <v-col>
                        <h6 class="font-weight-bold">
                          Irrigation Systems Health
                        </h6>
                        <v-row>
                          <v-col md="6" cols="12">
                            <span class="v-label theme--light">
                              {{
                                formattedIrrigationInspectionDate
                                  ? "Your irrigation inspection is scheduled for " +
                                    formattedIrrigationInspectionDate
                                  : ""
                              }}
                            </span>
                          </v-col>
                          <v-col md="6" cols="12">
                            <v-menu
                              ref="menu2"
                              v-model="menu2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formattedIrrigationInspectionDate"
                                  label="Irrigation Inspection Date"
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  @blur="
                                    irrigationInspectionDate = parseDate(
                                      formattedIrrigationInspectionDate
                                    )
                                  "
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="irrigationInspectionDate"
                                no-title
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-item-group
                              mandatory
                              v-model="orangeReport.irrigationSystemHealth"
                            >
                              <v-container>
                                <v-row>
                                  <v-col cols="12" md="4">
                                    <v-item v-slot="{ active, toggle }">
                                      <v-card
                                        :color="
                                          active
                                            ? 'blue lighten-4'
                                            : 'transparent'
                                        "
                                        dark
                                        @click="toggle"
                                      >
                                        <IrrigationSystemHealthItem
                                          name="All Go With The Flow"
                                          pictureUrl="/media/assets/irrigation-system-health/icons-all-go-with-the-flow.png"
                                        >
                                        </IrrigationSystemHealthItem>
                                      </v-card>
                                    </v-item>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-item v-slot="{ active, toggle }">
                                      <v-card
                                        :color="
                                          active
                                            ? 'red darken-4'
                                            : 'transparent'
                                        "
                                        dark
                                        @click="toggle"
                                      >
                                        <IrrigationSystemHealthItem
                                          name="Needs Attention"
                                          pictureUrl="/media/assets/irrigation-system-health/icons-needs-attention.png"
                                        >
                                        </IrrigationSystemHealthItem>
                                      </v-card>
                                    </v-item>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-item v-slot="{ active, toggle }">
                                      <v-card
                                        :color="
                                          active
                                            ? 'blue lighten-4'
                                            : 'transparent'
                                        "
                                        dark
                                        @click="toggle"
                                      >
                                        <IrrigationSystemHealthItem
                                          name="Under Repair"
                                          pictureUrl="/media/assets/irrigation-system-health/icons-under-repair.png"
                                        >
                                        </IrrigationSystemHealthItem>
                                      </v-card>
                                    </v-item>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-item-group>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row>
                      <v-col>
                        <h6 class="font-weight-bold">
                          Resident Notepad
                        </h6>
                        <v-textarea
                          v-model="orangeReport.residentNotepad"
                          solo
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row>
                      <v-col>
                        <h6 class="font-weight-bold">
                          Quarterly Community Enhancement Plan
                        </h6>
                        <v-progress-linear
                          color="blue"
                          :value="ninetyDayPlanProgress"
                          height="25"
                        >
                          <template v-slot:default="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                          </template></v-progress-linear
                        >
                        <v-row class="mt-4">
                          <v-col md="6" cols="12">
                            <span class="v-label theme--light">
                              Quarterly Orange Walk is scheduled for
                              {{ formattedQuarterlyOrangeWalkDate }}
                            </span>
                          </v-col>
                          <!-- <v-col md="6" cols="12">
                            <v-menu
                              ref="menu3"
                              v-model="menu3"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formattedQuarterlyOrangeWalkDate"
                                  label="Quarterly Orange Walk Date*"
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  :error-messages="
                                    formattedQuarterlyOrangeWalkDateErrors
                                  "
                                  @blur="
                                    quarterlyOrangeWalkDate = parseDate(
                                      formattedQuarterlyOrangeWalkDate
                                    )
                                  "
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="quarterlyOrangeWalkDate"
                                no-title
                                @input="menu3 = false"
                              ></v-date-picker> </v-menu
                          ></v-col> -->
                        </v-row>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row>
                      <v-col>
                        <h6 class="font-weight-bold">
                          Follow Up / Actions*
                        </h6>
                        <v-textarea
                          v-model="orangeReport.followUpActions"
                          solo
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
              <v-col cols="12" md="3">
                <v-container>
                  <v-row>
                    <v-col class="px-4 pb-n4" cols="12">
                      <span class="text-h6 font-weight-bold">
                        Upload Photos
                        <br />(max {{ maxFilesCount }} photos)
                      </span>
                      <v-row>
                        <v-col
                          v-for="image in photosObj"
                          cols="12"
                          class="darggable-col"
                          :key="image.id"
                        >
                          <MediaUploader
                            class="pb-5"
                            :key="image.id"
                            :id="`multiImagePost_${image.id}`"
                            :index="image.id"
                            :accept="accept"
                            :multiple="true"
                            :extensions="extensions"
                            :sizeImage="10485760"
                            :sizeVideo="104857600"
                            :fileId.sync="image.fileId"
                            :file-url.sync="image.url"
                            :isUploading.sync="isFileUploading"
                            @onMultipleUploaded="onMultipleAddFile"
                            @onChange="onChange"
                            @onDeleteFile="onDeleteFile"
                          />
                          <div>
                            <v-checkbox
                              v-if="image.url"
                              label="Display this image with the orange report listing"
                              v-model="image.showInReport"
                              @change="showInReportChanged(image)"
                            />
                            <v-textarea
                              v-if="image.url"
                              v-model="image.description"
                              label="Photo description"
                            ></v-textarea>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
          <!-- End Recent Orange Reports section -->
        </v-card-text>
        <v-card-actions v-if="!requestProcessing">
          <v-row>
            <v-col cols="12" md="9" class="text-right">
              <v-btn color="primary" class="mx-1" @click="saveOrangeReport"
                >Save Report for approval</v-btn
              >
              <router-link
                class="blue--text mx-1"
                :to="`/customers/${customerId}/orange-reports`"
                ><v-btn>Cancel</v-btn></router-link
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <!-- <v-card elevation="0" v-else>
        <v-card-text>
          <div class="text-center">
            <div class="text-subtitle-1 text-center">No Quarterly Plan</div>
            <router-link
              :to="`/customers/${customerId}/plans/new`"
              v-if="
                permissions.ninetyDayPlan && permissions.ninetyDayPlan.canCreate
              "
            >
              <v-btn class="orange-bg white--text">Create Quarterly Plan</v-btn>
            </router-link>
          </div>
        </v-card-text>
      </v-card> -->

      <v-dialog v-model="confirmDialog" persistent max-width="290">
        <v-card>
          <v-card-title>
            Confirmation
          </v-card-title>
          <v-card-text>
            Orange Report has been created
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="green darken-1" text @click="confirmDialog = false">
                Cancel
              </v-btn> -->
            <v-btn color="green darken-1" text @click="saveOrangeReportDialog">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import IrrigationSystemHealthItem from "@/view/components/IrrigationSystemHealthItem.vue";
import {
  GET_QUERY,
  API_CUSTOMERS,
  API_ORANGE_REPORTS,
  API_ORANGE_REPORT_SERVICES,
  // API_NINETY_DAY_PLAN,
  POST_DATA
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";

import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

// import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";
import permissionsHelper from "@/core/helpers/permissionsHelper";
// import draggable from "vuedraggable";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    IrrigationSystemHealthItem,
    MediaUploader
    // draggable
  },
  data: () => ({
    communityInfo: {},
    relatedCommunity: null,
    relatedCommunityItems: [],
    menu1: false,
    menu2: false,
    menu3: false,
    serviceDate: null,
    formattedServiceDate: null,
    irrigationInspectionDate: null,
    formattedIrrigationInspectionDate: null,
    quarterlyOrangeWalkDate: null,
    formattedQuarterlyOrangeWalkDate: null,
    irrigationSystemStatusList: [
      "AllGoWithTheFlow",
      "NeedsAttention",
      "UnderRepair"
    ],
    orangeReport: {
      id: "",
      name: "",
      dateCreated: null,
      serviceDate: null,
      mowService: false,
      edgeService: false,
      hedgesService: false,
      irrigationService: false,
      plantingService: false,
      weedControlService: false,
      debrisRemovalService: false,
      repairsService: false,
      fertilizationService: false,
      visitNotes: "",
      photos: [],
      irrigationSystemInspectionDate: null,
      irrigationSystemHealth: "AllGoWithTheFlow",
      residentNotepad: "",
      ninetyDayPlanProgress: 0,
      ninetyDayPlanTrack: true,
      quarterlyOrangeWalkDate: null,
      followUpActions: ""
    },
    options: {
      page: 1,
      itemsPerPage: 5
    },
    pageText: "",
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    confirmDialog: false,
    dbOrangeReportServices: [],
    renderedServices: [],
    ninetyDayPlan: {},
    photosObj: [{ id: 1, url: null, description: "", showInReport: false }],
    isFileUploading: false,
    videoExtensions: "mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt",
    permissions: {},
    requestProcessing: false,
    maxFilesCount: 8
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    // return true;
    return {
      formattedServiceDate: {
        required
      }
      // formattedQuarterlyOrangeWalkDate: {
      //   required
      // }
    };
  },
  watch: {
    serviceDate: function() {
      this.formattedServiceDate = this.formatDate(this.serviceDate);
    },
    irrigationInspectionDate: function() {
      if (this.irrigationInspectionDate) {
        this.formattedIrrigationInspectionDate = this.formatDate(
          this.irrigationInspectionDate
        );
      }
    },
    quarterlyOrangeWalkDate: function() {
      this.formattedQuarterlyOrangeWalkDate = this.formatDate(
        this.quarterlyOrangeWalkDate
      );
    }
  },
  async mounted() {
    // this.loading = true;
    // await this.getComunityInfo();
    // await this.createBreadcrumb();
    // await this.setInternalBreadcrumbs();
    // this.formattedServiceDate = this.formatDate(new Date());
    // this.loading = false;
  },
  async created() {
    this.orangeReport = {
      id: "",
      name: "",
      dateCreated: null,
      serviceDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      formattedServiceDate: null,
      mowService: false,
      edgeService: false,
      hedgesService: false,
      irrigationService: false,
      plantingService: false,
      weedControlService: false,
      debrisRemovalService: false,
      repairsService: false,
      fertilizationService: false,
      visitNotes: "",
      photos: [],
      photosObj: [{ id: 1, url: null, description: "", showInReport: false }],
      irrigationSystemInspectionDate: null,
      irrigationSystemHealth: "AllGoWithTheFlow",
      residentNotepad: "",
      ninetyDayPlanProgress: 0,
      ninetyDayPlanTrack: true,
      quarterlyOrangeWalkDate: null,
      followUpActions: "",
      renderedServices: []
    };
    this.serviceDate = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
    this.formattedServiceDate = this.formatDate(this.serviceDate);

    let self = this;
    self.loading = true;
    Promise.all([
      this.getComunityInfo(),
      this.getNinetyDayPlan(),
      permissionsHelper.getPermissions().then(this.getPermissions)
      // await this.getRecipients()
    ]).then(function() {
      self.loading = false;
    });

    this.getOrangeReportServices();
    this.getRecentOrangeReports();
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },
    async getOrangeReportServices() {
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_ORANGE_REPORT_SERVICES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.dbOrangeReportServices = response.data.$values;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },
    async getRecentOrangeReports() {
      let reportsUrl = `${API_CUSTOMERS}/${this.customerId}/orangeReports`;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: reportsUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.recentOrangeReports = response.data.$values;
            if (this.recentOrangeReports.length > 0) {
              let sortedOR = this.recentOrangeReports.slice().sort((a, b) => {
                return b.id - a.id;
              });

              let mostRecentOR = sortedOR[0];
              this.irrigationInspectionDate = null;
              if (mostRecentOR && mostRecentOR.irrigationInspectionDate) {
                this.irrigationInspectionDate = new Date(
                  mostRecentOR.irrigationInspectionDate
                )
                  .toISOString()
                  .substr(0, 10);
              }
              if (mostRecentOR) {
                this.orangeReport.irrigationSystemHealth =
                  mostRecentOR.irrigationSystemHealth;
              }
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },
    async getNinetyDayPlan() {
      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_CUSTOMERS}/${this.customerId}/plans/current`
        })
        .then(response => {
          this.ninetyDayPlan = null;
          if (response.status >= 200 || response.status <= 204) {
            this.ninetyDayPlan = response.data;
            if (this.ninetyDayPlan) {
              this.ninetyDayPlanProgress = this.ninetyDayPlan.progress;

              this.quarterlyOrangeWalkDate = new Date(
                this.ninetyDayPlan.orangeWalkDate
              )
                .toISOString()
                .substr(0, 10);
              this.formattedQuarterlyOrangeWalkDate = this.formatDate(
                this.quarterlyOrangeWalkDate
              );
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Orange Reports",
          route: "/customers/" + this.customerId + "/orange-reports"
        },
        { title: "New Orange Report" }
      ];
    },
    saveOrangeReportDialog() {
      this.confirmDialog = false;
      this.$router.push({
        name: "OrangeReports",
        params: { customerId: this.customerId }
      });
    },
    saveOrangeReport() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.orangeReport.id = this.communityInfo.recentOrangeReports.length;
      this.orangeReport.communityId = this.communityInfo.aspireId;
      // this.orangeReport.residentNotepad = this.orangeReport.residentNotepad;
      // this.orangeReport.followUpActions = this.orangeReport.followUpActions;
      this.orangeReport.irrigationSystemHealth = this.orangeReport.irrigationSystemHealth;
      if (this.irrigationInspectionDate) {
        this.orangeReport.irrigationInspectionDate = moment.utc(
          this.irrigationInspectionDate
        );
      }
      // this.orangeReport.dateCreated = Date.now();
      this.orangeReport.isApproved = false;
      this.orangeReport.serviceDate = moment.utc(this.serviceDate);
      if (this.irrigationInspectionDate) {
        this.orangeReport.irrigationSystemInspectionDate = moment.utc(
          this.irrigationInspectionDate
        );
      }
      if (this.ninetyDayPlan) {
        this.orangeReport.orangeWalkDate = moment.utc(
          this.quarterlyOrangeWalkDate
        );
      } else {
        this.orangeReport.orangeWalkDate = null;
      }

      if (this.ninetyDayPlan) {
        this.orangeReport.ninetyDayPlanProgress = Math.ceil(
          this.ninetyDayPlan.progress
        );
      } else {
        this.orangeReport.ninetyDayPlanProgress = 0;
      }
      if (this.ninetyDayPlan)
        this.orangeReport.ninetyDayPlanId = this.ninetyDayPlan.id;
      else this.orangeReport.ninetyDayPlanId = null;

      // this.orangeReport.name =
      //   "Orange Report - " + this.orangeReport.serviceDate;

      this.orangeReport.servicesRendered = [];
      for (let i = 0; i < this.dbOrangeReportServices.length; i++) {
        if (
          this.renderedServices.indexOf(this.dbOrangeReportServices[i].name) >
          -1
        )
          this.orangeReport.servicesRendered.push(
            this.dbOrangeReportServices[i]
          );
      }

      var self = this;
      this.orangeReport.photos = this.photosObj
        .filter(
          i =>
            i.url &&
            i.url.length > 0 &&
            !self.videoExtensions.includes(self.getExtensionFromUrl(i.url))
        )
        .map(i => {
          return {
            id: i.fileId,
            fileUrl: i.url,
            description: i.description,
            showInReport: i.showInReport
          };
        });
      this.orangeReport.photos = this.orangeReport.photos.filter(
        (item, index) => this.orangeReport.photos.indexOf(item) === index
      ); //remove duplicates

      this.communityInfo.orangeReports.push(this.orangeReport);

      this.requestProcessing = true;

      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_ORANGE_REPORTS}`,
          data: this.orangeReport
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent =
              "Orange Report has been created successfully";
            self.snackbarColor = "green";
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";

            self.$snackbar.showMessage({
              content: self.snackbarContent,
              color: self.snackbarColor
            });
          }

          self.confirmDialog = true;
          self.requestProcessing = false;
        });

      // this.saveCustomer();
    },
    showInReportChanged(image) {
      var found = this.photosObj.find(f => f.url == image.url);

      if (found) {
        for (let l = 0; l < this.photosObj.length; l++) {
          let item = this.photosObj[l];
          if (item.url == image.url) continue;

          item.showInReport = false;
        }
      }
    },
    // onShowInReportChanged(fileUrl) {
    //   var found = this.photosObj.find(f => f.url == fileUrl);

    //   if (found) {
    //     for (let i = 0; i < this.photosObj.length; i++) {
    //       let item = this.photosObj[i];
    //       item.showInReport = item.fileUrl == fileUrl;
    //     }
    //   }
    // },
    onMultipleAddFile(files) {
      if (this.photosObj.length >= this.maxFilesCount) return;

      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.photosObj.map(i => i.id));
        this.photosObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id,
          description: null,
          showInReport: false
        });

        if (this.photosObj.length === this.maxFilesCount) break;
      }

      if (this.photosObj.length < this.maxFilesCount) {
        let max = Math.max(...this.photosObj.map(i => i.id));
        this.photosObj.push({
          id: (max += 1),
          url: null,
          description: null,
          showInReport: false
        });
      }

      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.photosObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;

        var extension = this.getExtensionFromUrl(file.fileUrl);
        var isVideo = this.videoExtensions.includes(extension);
        if (isVideo) {
          var videoInStore = 0;
          this.photosObj.forEach(obj => {
            var ext = this.getExtensionFromUrl(obj.url);
            var isVideo = this.videoExtensions.includes(ext);
            if (isVideo) videoInStore += 1;
          });
          if (videoInStore > 1) {
            let found = this.photosObj.find(f => f.url == file.fileUrl);
            let index = this.photosObj.indexOf(found);
            this.photosObj.splice(index, 1);
            this.saveResult.text = "You can add only one video";
            this.showModal = true;
          }
        }
      }
    },
    onDeleteFile(fileData) {
      // let initialPhotosCount = this.photosObj.length;
      let index = this.photosObj.findIndex(f => f.id === fileData.index);
      this.photosObj.splice(index, 1);

      if (this.photosObj.filter(el => !el.url).length == 0) {
        let max = Math.max(...this.photosObj.map(i => i.id));
        this.photosObj.push({
          id: (max += 1),
          url: null,
          description: null,
          showInReport: false
        });
      }
    },
    onDragCallBack(evt) {
      return (
        evt.draggedContext.element.url != null &&
        evt.relatedContext.element.url != null
      );
    },
    getExtensionFromUrl(url) {
      if (url) {
        var splited = url.split(".");
        return splited.length > 1 ? splited[splited.length - 1] : null;
      }
      return null;
    },

    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Orange Reports",
          route: "/customers/" + this.customerId + "/orange-reports"
        },
        { title: "New Orange Report" }
      ]);
    },
    // async submit() {
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     return;
    //   }
    // },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    accept() {
      return "image/png,image/gif,image/jpeg,image/webp,video/mp4,video/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt";
    },
    // formattedIrrigationInspectionDateErrors() {
    //   const errors = [];
    //   if (!this.$v.formattedIrrigationInspectionDate.$dirty) return errors;
    //   !this.$v.formattedIrrigationInspectionDate.required &&
    //     errors.push("Required.");
    //   return errors;
    // },
    formattedServiceDateErrors() {
      const errors = [];
      if (!this.$v.formattedServiceDate.$dirty) return errors;
      !this.$v.formattedServiceDate.required && errors.push("Required.");
      return errors;
    },
    formattedQuarterlyOrangeWalkDateErrors() {
      const errors = [];
      if (!this.ninetyDayPlan) return errors;

      if (!this.$v.formattedQuarterlyOrangeWalkDate.$dirty) return errors;
      !this.$v.formattedQuarterlyOrangeWalkDate.required &&
        errors.push("Required.");
      return errors;
    }
  }
};
</script>
