<template>
  <div v-if="branchOfficeStaff">
    <v-row>
      <v-col>
        <!-- <div>
          <span class="font-weight-bold">Branch Office: </span
          >{{ branchOfficeStaff.branchOfficeName }}
        </div> -->
        <div>
          <span class="font-weight-bold">Community Address: </span><br />
          {{ branchOfficeStaff.communityAddress }}, <br />
          {{ branchOfficeStaff.communityCity }},
          {{ branchOfficeStaff.communityStateCode }}
          {{ branchOfficeStaff.communityPostalCode }}
        </div>
        <!-- <div>
          <span class="font-weight-bold">Branch Manager: </span
          >{{ branchOfficeStaff.branchManager }}
        </div> -->
      </v-col>
      <v-col>
        <div>
          <span class="font-weight-bold">Account Manager: </span><br />{{
            branchOfficeStaff.accountManager
          }}
        </div>
        <!-- <div>
          <span class="font-weight-bold">Crew Leader: </span
          >{{ branchOfficeStaff.crewLeader }}
        </div> -->
      </v-col>
      <v-col>
        <!-- <div>
          <span class="font-weight-bold">Regional Director: </span
          >{{ branchOfficeStaff.regionalDirector }}
        </div> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "BranchOfficeInfo",
  props: {
    branchOfficeStaff: Object
  }
};
</script>
