<template>
  <div>
    <v-card outlined elevation="0" class="service-box">
      <h6 class="orange-bg white--text text-center py-1">Service Center</h6>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" lg="4" sm="6" xs="12">
            <div>
              <router-link
                :to="`/customers/${id}/plans`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Quarterly Plans
                </a>
              </router-link>
            </div>
            <div>
              <router-link
                :to="`/customers/${id}/orange-reports`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Orange Reports
                </a>
              </router-link>
            </div>
            <div>
              <router-link
                :to="`/customers/${id}/messages`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Messages
                  <v-icon small class="mr-2" color="green" v-if="hasNewMessage">
                    mdi-email
                  </v-icon>
                </a>
              </router-link>
            </div>
          </v-col>
          <v-col cols="12" lg="4" sm="6" xs="12">
            <div>
              <router-link
                :to="`/customers/${id}/invoices`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Invoices
                </a></router-link
              >
            </div>
            <div>
              <router-link
                :to="`/customers/${id}/proposals`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Proposals
                </a></router-link
              >
            </div>
          </v-col>
          <v-col cols="12" lg="4" sm="6" xs="12">
            <div>
              <router-link
                :to="`/customers/${id}/documents`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Documents
                </a></router-link
              >
            </div>
            <div>
              <router-link
                v-if="
                  permissions &&
                    permissions.manageUsers &&
                    permissions.manageUsers.canAccess
                "
                :to="`/customers/${id}/manage-portal-access`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Manage Users
                </a>
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { GET_QUERY, API_MESSAGES } from "@/core/store/crud.module";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  name: "ServiceCenterBox",
  props: {
    id: [String, Number]
  },
  data: () => ({
    hasNewMessage: false,
    permissions: []
  }),
  async mounted() {
    permissionsHelper.getPermissions().then(this.getPermissions);
    this.getMessages();
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    async getMessages() {
      this.isLoading = true;
      let self = this;

      this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_MESSAGES}`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let items = response.data.$values;

            if (items.filter(el => el.isNew).length > 0)
              this.hasNewMessage = true;
          }
          self.isLoading = false;
        });
    }
  }
};
</script>
