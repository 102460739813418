var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"service-box",attrs:{"outlined":"","elevation":"0"}},[_c('h6',{staticClass:"orange-bg white--text text-center py-1"},[_vm._v("Service Center")]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"4","sm":"6","xs":"12"}},[_c('div',[_c('router-link',{attrs:{"to":("/customers/" + _vm.id + "/plans")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Quarterly Plans ")])]}}])})],1),_c('div',[_c('router-link',{attrs:{"to":("/customers/" + _vm.id + "/orange-reports")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Orange Reports ")])]}}])})],1),_c('div',[_c('router-link',{attrs:{"to":("/customers/" + _vm.id + "/messages")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Messages "),(_vm.hasNewMessage)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"}},[_vm._v(" mdi-email ")]):_vm._e()],1)]}}])})],1)]),_c('v-col',{attrs:{"cols":"12","lg":"4","sm":"6","xs":"12"}},[_c('div',[_c('router-link',{attrs:{"to":("/customers/" + _vm.id + "/invoices")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Invoices ")])]}}])})],1),_c('div',[_c('router-link',{attrs:{"to":("/customers/" + _vm.id + "/proposals")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Proposals ")])]}}])})],1)]),_c('v-col',{attrs:{"cols":"12","lg":"4","sm":"6","xs":"12"}},[_c('div',[_c('router-link',{attrs:{"to":("/customers/" + _vm.id + "/documents")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Documents ")])]}}])})],1),_c('div',[(
                _vm.permissions &&
                  _vm.permissions.manageUsers &&
                  _vm.permissions.manageUsers.canAccess
              )?_c('router-link',{attrs:{"to":("/customers/" + _vm.id + "/manage-portal-access")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Manage Users ")])]}}],null,false,1815848773)}):_vm._e()],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }